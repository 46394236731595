import React from 'react';
import "../../styles/GlobalPages.css";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import api from "../../components/servicios/api";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import PiePagina from '../../components/Navbar/PiePagina';
import { Bar } from 'react-chartjs-2';

let año;
let mes;
let userData;
let f = new Date();
let arrayYear = [];
let arrayMonth = [];
let d = new Date(f.getFullYear(), f.getMonth(), 1);

for (let i = 0; i < 12; i++) {
    arrayYear.push(d.getFullYear());
    arrayMonth.push(d.getMonth() + 1);
    d.setMonth(d.getMonth() - 1);
}
//2º - Recorremos el array de fechas y construimos los labels para el grafico
let arrayLabels = [];
for (let i = 0; i < arrayYear.length; i++) {
    arrayLabels.push(("0" + arrayMonth[i]).slice(-2) + '-' + arrayYear[i]);
}
class EstadisticasTranslated extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            datos: undefined,
            sinDatos: false,
        }

    };

    componentDidMount() {
        this.fetchData();
    }


    click_factura = () => {
        // window.location = '/NuevaOferta';
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null, datos: undefined });
        userData = '';
        try {
            await api.Clientes.ofertas().then(
                ({ data }) => (userData = data)
            );
            if (Array.isArray(userData)) {
                this.setState({ loading: false, datos: userData, sinDatos: false });
            } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>listarClientes</b>" == userData) {
                this.setState({ loading: false, sinDatos: true, datos: false });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };


    render() {

        const opciones = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: process.env.REACT_APP_CONSUMO_EJES,
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: process.env.REACT_APP_CONSUMO_EJES,
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: process.env.REACT_APP_CONSUMO_EJES,
                }
            }
        }

        const grafico = () => ({
            labels: arrayLabels,
            datasets: [
                {
                    label: 'Enviadas',
                    backgroundColor: process.env.REACT_APP_CONSUMO_1,
                    borderColor: process.env.REACT_APP_CONSUMO_1,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_3,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [4, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    label: 'Aceptadas',
                    backgroundColor: process.env.REACT_APP_CONSUMO_2,
                    borderColor: process.env.REACT_APP_CONSUMO_2,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_4,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ]
        })
        const data = grafico()

        const grafico1 = () => ({
            labels: arrayLabels,
            datasets: [
                {
                    label: 'Email',
                    backgroundColor: process.env.REACT_APP_CONSUMO_1,
                    borderColor: process.env.REACT_APP_CONSUMO_1,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_3,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    label: 'SMS',
                    backgroundColor: process.env.REACT_APP_CONSUMO_2,
                    borderColor: process.env.REACT_APP_CONSUMO_2,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_4,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [3, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    label: 'Telf',
                    backgroundColor: 'grey',
                    borderColor: 'grey',
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_4,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
            ]
        })
        const data1 = grafico1()

        const grafico2 = () => ({
            labels: arrayLabels,
            datasets: [
                {
                    label: 'Pablo',
                    backgroundColor: process.env.REACT_APP_CONSUMO_1,
                    borderColor: process.env.REACT_APP_CONSUMO_1,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_3,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    label: 'Qtel',
                    backgroundColor: process.env.REACT_APP_CONSUMO_2,
                    borderColor: process.env.REACT_APP_CONSUMO_2,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_4,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: [1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ]
        })
        const data2 = grafico2()



        const getElementAtEvent = async element => {
            if (!element.length) return

            const { _datasetIndex: datasetIndex, _index: index } = element[0]
            await this.setState({ FechaLabel: `Fecha : ` });
            await this.setState({ Fecha: `${data.labels[index]}` });
            //this.setState({ TipoLlamada: ` : ${data.datasets[datasetIndex].data[index]}`, mostrar: false });
            let fecha = this.state.Fecha.split('-');
            año = fecha[1];
            mes = fecha[0];
        }
        const getDatasetAtEvent = dataset => {
        }

        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span>{/*t('Clientes.titulo',)*/} Estadísticas Enviadas</span>
                </div>
                <div className='ofertas-grafico'>Ofertas Totales</div>
                <div className={this.state.loading ? 'hidden' : 'grafico'} style={{ width: '100%', height: '300px' }}>

                    <Bar data={grafico}
                        options={opciones}
                        getDatasetAtEvent={getDatasetAtEvent}
                        getElementAtEvent={getElementAtEvent}
                        onClick={this.handleChange}
                    />
                </div>
                <div className='ofertas-grafico'>Canal de envío</div>
                <div className={this.state.loading ? 'hidden' : 'grafico'} style={{ width: '100%', height: '300px' }}>

                    <Bar data={grafico1}
                        options={opciones}
                        getDatasetAtEvent={getDatasetAtEvent}
                        getElementAtEvent={getElementAtEvent}
                        onClick={this.handleChange}
                    />
                </div>
                <div className='ofertas-grafico'>Usuario</div>
                <div className={this.state.loading ? 'hidden' : 'grafico'} style={{ width: '100%', height: '300px' }}>

                    <Bar data={grafico2}
                        options={opciones}
                        getDatasetAtEvent={getDatasetAtEvent}
                        getElementAtEvent={getElementAtEvent}
                        onClick={this.handleChange}
                    />
                </div>
                <PiePagina />
            </div>
        );
    }
}
const Estadisticas = withTranslation('common')(EstadisticasTranslated)
export default Estadisticas;